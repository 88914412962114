import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text, Span } from "../../../components/Core";
import { device } from "../../../utils";
import MainImage from '../../../assets/image/jpeg/active-acne.jpg';


const ImgContainer = styled.div`
  margin-top: 20px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(15%, -50%);
  }
`;


const Newbut = styled.div`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-top: auto;
margin-bottom: 4em;
padding-top: 1em;
padding-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
opacity: 0.4;

color: #fff;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
max-width: 100%;
    display: inline-block;
`;




const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;










const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;


width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #000;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 0px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;



const Secti = styled.section`


position: relative;




@media screen and (max-width: 767px)
{
  padding-top:1em;
  padding-bottom:0px;
  margin-bottom:0px;
 }

`;




const Sect = styled.section`

  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;

`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const BAcnffeCauddsesPage = () => (
  <>
    {/* <!-- Content section 1 --> */}
  
    <Section bg="#ffffff" py={4}>
        <Container className="pt-3 pb-3">
          <Row className="pb-4 align-items-center">
            <Col lg="12" className="order-lg-1 mt-lg-0  pb-lg-5">
              <div
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <div>
          <Iwrap>

          
          <Itext as="h2">Milia <span>London</span>
</Itext> 
          </Iwrap>
                <SecondText>
                Causes of Milia
                </SecondText>

               
           
              </div>
            </div>
          
          </Col>

         
        </Row>

        <Row className="align-items-center">
            <Col lg="12">
            <Text>
            Milia are caused by the accumulation of dead skin  and oil in the pores. Small cysts arise when the skin's natural exfoliation process is disturbed or when there is an overabundance of keratin, a protein that aids in the formation of skin and hair. Milia can be caused by a variety of factors, including:

            <br /> <br />

Skin trauma: Trauma to the skin, such as burns or blisters, can result in the formation of milia while the skin heals.
<br /> <br />
Some skin products: Using heavy or oily skin products can trap dead skin  and oil in the pores, causing milia to form.
<br /> <br />
Sun damage: Sun exposure can cause skin damage, leading to the production of milia.
<br /> <br />
Hormonal changes, such as those seen during pregnancy, can lead to the formation of milia.
<br /> <br />
Genetic factors: Some people may be predisposed to getting milia due to genetics.

 


<br /> <br />
               </Text>
            </Col>

        </Row>

        <Row className="align-items-center">
            <Col lg="12">
              <Text>
              Milia is characterised by the emergence of small, white or yellowish lumps on the skin, most commonly around the eyes, cheeks, forehead, and nose. The bumps are usually minuscule, the size of a pinhead to a grain of sand. <br /> <br />They are usually painless, however some people may experience itching or irritation in the affected area. Milia can appear single or in clusters, and while they are normally harmless, some people may find them unsightly. Milia can become inflammatory or infected in rare circumstances, causing redness, swelling, and pain in the affected area.

 

</Text>
            </Col>
        </Row>
       
   
      </Container>

    
     
    </Section>
 
    
  </>
);

export default BAcnffeCauddsesPage;
